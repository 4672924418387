<template>
  <v-snackbar v-model="isShowSnackbar" :color="color" top right>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="isShowSnackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  computed: {
    isShowSnackbar: {
      get() {
        return this.$store.state.isShowSnackbar;
      },
      set(val) {
        if (!val) {
          this.$store.commit('hideSnackbar');
        }
      }
    },
    color() {
      return this.$store.state.color;
    },
    message() {
      return this.$store.state.message;
    }
  }
};
</script>
