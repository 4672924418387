const storage = {
  get: (key) =>
    new Promise((res, rej) => {
      try {
        const result = JSON.parse(localStorage.getItem(key));
        if (result === null || result === undefined)
          throw new Error('key not found');
        res(result);
      } catch (error) {
        rej(error);
      }
    }),
  set: (key, value) =>
    new Promise((res, rej) => {
      try {
        const val = JSON.stringify(value);
        localStorage.setItem(key, val);
        res();
      } catch (error) {
        rej(error);
      }
    })
};

export default storage;
