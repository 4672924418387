import { SharedPlugin } from '@inception/connect-shared';
import store from './store';
import Storage from './utils/storage.js';

export const { auth, messages, partner, supportChat, calendar, api, utils } =
  new SharedPlugin({
    store: store,
    localStorage: Storage,
    isProduction: process.env.NODE_ENV === 'production',
    appEnv: process.env.VUE_APP_ENV,
    authLoggedInCallBack: () => null,
    messageShownCallback: () => null,
    conversationSelected: () => null
  });

const getErrorMessage = (code, message = null) => {
  switch (code) {
    case 400:
      return message ?? 'Uh oh! an unknown error has occurred';
    case 401:
      return (
        message ?? 'Unauthorized! please try logging out and logging back in'
      );
    case 404:
      return { message: message ?? 'Not found', code };
    case 500:
      return 'Oops. An error occured on our end! Please check back in a few minutes';
    case 502:
      return 'Oops. Were having trouble connecting. Check back in a few minutes.';
    case 503:
      return 'Oops. Were having trouble connecting. Check back in a few minutes.';
    case 504:
      return 'Oops. Were having trouble connecting. Check back in a few minutes.';
    default:
      return 'Uh oh! an unknown error has occurred';
  }
};

api.GetInstance().interceptors.response.use(
  (success) => success,
  (error) => {
    const message = error.response?.data?.message ?? error?.response?.data;

    if (error.response?.status === 401) {
      window.location.href = '/auth/logout';
    }

    return Promise.reject(getErrorMessage(+error.response?.status, message));
  }
);
