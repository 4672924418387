import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#1F6070',
        secondary: '#253035',
        accent: '#ffffff'
      },
      light: {
        primary: '#1F6070',
        secondary: '#253035',
        accent: '#ffffff'
      }
    },
    options: {
      customProperties: true
    }
  }
});
