import TopBarNotification from '@/components/TopBarNotification';

const Topbar = {
  install(Vue, options) {
    this.EventBus = new Vue();
    Vue.component('topbar-notification', Topbar);
    Vue.prototype.$topbar = {
      show(params) {
        Topbar.EventBus.$emit('show', params);
      },
      hide() {
        Topbar.EventBus.$emit('hide');
      }
    };
  }
};

export default Topbar;
