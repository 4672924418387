<template>
  <div class="topbar-fixed">
    <v-alert
      v-show="open"
      :dismissible="dismissible"
      :color="color"
      class="text-center mb-0 mx-n1"
    >
      <a @click="action(onAction)" :class="`text-decoration-none ${color}`">
        {{ text }}
      </a>
    </v-alert>
  </div>
</template>

<script>
import Topbar from '../plugins/topbar';

export default {
  name: 'TopBarNotification',
  data() {
    return {
      open: false,
      color: '',
      text: '',
      onAction: null,
      dismissible: false
    };
  },
  methods: {
    async action(onAction) {
      if (typeof onAction === 'function') {
        try {
          await onAction();
        } catch (e) {
          console.log('Error: ', e);
        } finally {
          this.hide();
        }
      } else {
        this.hide();
      }
    },
    show(params) {
      this.open = true;
      this.color = params.color;
      this.text = params.text;
      this.onAction = params.onAction;
      this.dismissible = params.dismissible;
    },
    hide() {
      this.open = false;
    }
  },
  beforeMount() {
    Topbar.EventBus.$on('show', (params) => {
      this.show(params);
    });
    Topbar.EventBus.$on('hide', (params) => {
      this.hide(params);
    });
  }
};
</script>

<style scoped>
.topbar-fixed {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
</style>
