import Vue from 'vue';
import Vuex from 'vuex';
import fireBase, { messaging } from '../utils/fireBase';
import {
  compareAndStoreTokens,
  getNotificationPermissions,
  MessagingStatus
} from '../utils/messaging';
import { getToken, onMessage } from 'firebase/messaging';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isShowSnackbar: false,
    message: '',
    color: '',
    routeName: '',
    messagingStatus: false,
    unreadStatus: null,
    partner: null,
    partners: null,
    isPulling: false,
    accessDenied: false
  },
  getters: {
    messagesEnabled: (state) => {
      state.messagingStatus.success;
    },
    unreadStatus: (state) => {
      state.unreadStatus;
    }
  },
  mutations: {
    showSnackbar(state, { message, color }) {
      state.isShowSnackbar = true;
      state.message = message;
      state.color = color;
    },
    hideSnackbar(state) {
      state.isShowSnackbar = false;
      state.message = '';
    },
    setMessagingStatus(state, status) {
      if (status.success !== undefined) {
        state.messagingStatus = status;
      } else {
        state.messagingStatus = MessagingStatus.UNKNOWN_ERROR(status);
      }
    },
    setUpdateUnreadCount(state, status) {
      state.unreadStatus = state;
    },
    setUpdateRouteName(state, routeName) {
      state.routeName = routeName;
    },
    setInvitePending(state, status) {
      state.partner.invitePending = status;
    },
    setViewPartner(state, status) {
      state.partner.viewPartner = status;
    },
    setPartner(state, payload) {
      state.partner = payload;
    },
    setPartners(state, payload) {
      state.partners = payload;
    },
    setPatientEmail(state, email) {
      state.partner.patientEmail = email;
    },
    setOwnerName(state, name) {
      state.partner.ownerName = name;
    },
    setPulling(state, payload) {
      state.isPulling = payload;
    },
    setAccessDenied(state, payload) {
      state.accessDenied = payload;
    }
  },
  actions: {
    initializeMessaging({ commit, state, dispatch }) {
      getNotificationPermissions(state.messagingStatus === false)
        .then(
          async () =>
            await getToken(messaging, {
              vapidKey:
                'BPH6quKxKkXdIQLfLdlvxJtDGc0OcdXOvkIsSoo1bVuB_DjgDdkmhfWoXnemhMhKEEeiT_i1MRntZS1xE8NUWMI'
            })
        )
        .then(compareAndStoreTokens)
        .then(() => {
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (
              event &&
              event.data &&
              event.data.notification &&
              event.data.notification.title
            ) {
              let title = event.data.notification.title;
              dispatch('messages/pullNotification', title.toLowerCase());
            }
          });
        })
        .finally(() => commit('setMessagingStatus', MessagingStatus.GRANTED));
    }
  },
  modules: {}
});

export default store;
