import { api } from '../sharedPlugin';
import storage from './storage';
export const logoutHelper = async (logoutFn) =>
  storage
    .get('firebaseToken')
    .then((token) => api.Fcm.removeToken({ token: token }))
    .catch(() => {
      console.log('couldnt find fcm token in storage');
    })
    .then(logoutFn)
    .then(() => storage.set('firebaseToken', ''))
    .then(() => location.reload());
