import ModalDialog from '@/components/ModalDialog';

const Modal = {
  install(Vue, options) {
    this.EventBus = new Vue();
    Vue.component('app-modal', ModalDialog);
    Vue.prototype.$modal = {
      show(params) {
        Modal.EventBus.$emit('show', params);
      },
      hide() {
        Modal.EventBus.$emit('hide');
      }
    };
  }
};

export default Modal;
