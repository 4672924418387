// import firebase from 'firebase/app';
// import 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import device from './device';

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyD4Pwutng5oT8i0v1IkW3_8zgs58C8NT9c',
  authDomain: 'conne-4091a.firebaseapp.com',
  projectId: 'conne-4091a',
  storageBucket: 'conne-4091a.appspot.com',
  messagingSenderId: '1074895579375',
  appId: '1:1074895579375:web:8211800a5a64d9a2bfd31a',
  measurementId: 'G-TE7BY3BEH1'
};
var messaging = {};
const firebase = initializeApp(config);

if (device() === 'desktop') {
  messaging = getMessaging(firebase);
}
export { messaging };

export default firebase;
