<template>
  <div>
    <router-view />
    <ModalDialog />
  </div>
</template>
<script>
import { auth, api } from './sharedPlugin';
import { mapActions } from 'vuex';
import ModalDialog from './components/ModalDialog';

export default {
  components: {
    ModalDialog
  },
  computed: {
    ...auth.mapComputed(['loggedIn', 'user'])
  },
  methods: {
    ...mapActions(['initializeMessaging'])
  },
  watch: {
    loggedIn(val) {
      //if user is logged in set up firebase
      if (val > 0) {
        this.initializeMessaging();
      }
    }
  }
};
</script>

<style>
@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Heavy.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SF-Pro-Display-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans.ttf') format('truetype');
}

* {
  font-family: 'SF Pro Display', sans-serif;
}
</style>
