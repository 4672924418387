<template>
  <v-dialog v-model="open" max-width="500">
    <v-card class="py-6 pb-2 px-4">
      <v-toolbar v-if="title" flat>
        <v-card-title class="px-1">{{ title }}</v-card-title>
        <v-spacer />
        <v-btn icon @click="hide">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="text" class="px-5" v-html="text"></v-card-text>

      <v-card-actions class="pb-8" v-if="buttons && buttons.length">
        <v-row>
          <v-col
            v-for="(button, index) in buttons"
            :key="index"
            :md="columnsButtons"
            class="py-0"
          >
            <v-btn
              :color="`${button.color}`"
              rounded
              block
              large
              @click="action(button)"
              :loading="submitting"
              :disabled="submitting"
            >
              {{ button.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Modal from '../plugins/modal';
export default {
  name: 'ModalDialog',
  data() {
    return {
      open: false,
      submitting: false,
      title: '',
      text: '',
      buttons: []
    };
  },
  computed: {
    columnsButtons() {
      const dataColumns = {
        1: '12',
        2: '6'
      };
      return dataColumns[this.buttons && this.buttons.length] || '12';
    }
  },
  methods: {
    async action({ onAction }) {
      if (typeof onAction === 'function') {
        this.submitting = true;
        try {
          await onAction();
        } catch (e) {
          console.log('Error: ', e);
        } finally {
          this.submitting = false;
          this.hide();
        }
      } else {
        this.hide();
      }
    },
    show(params) {
      this.open = true;
      this.submitting = false;
      this.title = params.title;
      this.text = params.text;
      this.buttons = params.buttons;
    },
    hide() {
      this.open = false;
    }
  },
  beforeMount() {
    Modal.EventBus.$on('show', (params) => {
      this.show(params);
    });
    Modal.EventBus.$on('hide', (params) => {
      this.hide(params);
    });
  }
};
</script>
